//////////////////
// Cookie Notice Controller
//////////////////
App.CookieNotice = (function() {

	var selectors = {
		notice: ".cookie-notice",
		closeButton: ".cookie-notice-accept"
	};

	var flags = {
		COOKIE_NAME: "NOTICE_ACCEPT",
		COOKIE_STATUS: "agreed"
	};

	var notice, closeButton;

	function init() {
		try {
			notice = $(selectors.notice);
			closeButton = notice.find(selectors.closeButton);
			render();
		} catch(e) {
			console.log("Error locating " + selectors.notice, e);
		}
	}

	function createCookie(cookieName, cookieValue, daysToExpire) {
    var date = new Date();
    date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));
    document.cookie = cookieName + "=" + cookieValue + "; expires=" + date.toGMTString();
	}

	function accessCookie(cookieName){
	  var name = cookieName + "=";
	  var allCookieArray = document.cookie.split(';');
	  for(var i=0; i < allCookieArray.length; i++)  {
	    var temp = allCookieArray[i].trim();
	    if (temp.indexOf(name)==0) {
				return temp.substring(name.length,temp.length);
			}
		}
		return "";
	}

	function render() {

		// Show cookies notice if cookie not set
		var cookie = accessCookie(flags.COOKIE_NAME);
		if (accessCookie(flags.COOKIE_NAME) != flags.COOKIE_STATUS) {

			notice.css("display", "block");
		  gsap.to(notice, { bottom: 0 , duration: 2, delay: 1 });

			// Enable button and track cookie set
			closeButton.on("click", function() {
				createCookie(flags.COOKIE_NAME, flags.COOKIE_STATUS, 365 * 2);
			  gsap.to(notice, { bottom: -(notice.outerHeight()), display: "none" , duration: .5 });
				return(false);
			})
		} else {
			// If not needed, strip from the DOM
			notice.remove();
		}
	}

	// App.register(function() {
	// 	App.log("Registered: App.CookieNotice");
	// 	init();
	// });

})();
