//////////////////
// Simple Javascript Manager
//////////////////
// Cash javascript library used for base DOM manipulation
// @see https://github.com/kenwheeler/cash
//////////////////

var App = { Debug: true, Queue: [] };
App.onInit = function(func) {
	if(func) this.Queue.push(func);
}
App.register = function(init) {
	App.onInit(init);
}
App.init = function() {
	while (this.Queue.length){
  	this.Queue.shift().call();
	}
}
App.log = function() {
	if(!App.Debug || App.Debug == true) {
		console.log.apply(console, arguments);
	}
}
///
///
window.App = App;
///
///
